<template>
    <section>
        <ValidationObserver ref="validacion" v-slot="{ valid }">
            <modal
            ref="modalVincular"
            :titulo="isEmail ? 'Ingresa correo electrónico' : 'Ingresa número celular'"
            icon="password"
            adicional="Enviar"
            no-aceptar
            @adicional="generarCodigoRecuperacion(valid)"
            >
                <div class="row justify-center mb-5 mt-3">
                    <div class="" style="width:349px;">
                        <p class="text-general px-3">
                            {{ isEmail ? 'Correo electrónico' : 'Número celular' }}
                        </p>
                        <div v-if="!isEmail" class="col-12 px-0 position-relative py-2">
                            <el-select
                            v-model="pais"
                            placeholder="Seleccione un país"
                            filterable
                            class="w-100 br-6"
                            >
                                <el-option
                                v-for="(item, i) in proyecto.paises"
                                :key="i"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <ValidationProvider v-slot="{errors}" vid="usuario" :rules="`${isEmail ? 'email': 'numeric'}|required|max:500`" :name="isEmail ? 'correo' : 'número celular'">
                            <el-input v-model="usuario" class="w-100" :class="{'input-login-edit': !isEmail}">
                                <i
                                v-if="errors[0]"
                                slot="suffix"
                                style="color:#FF3333;padding-top:10px;"
                                class="icon-attention-alt f-20 d-flex"
                                />
                                <template v-if="!isEmail" slot="prepend">
                                    <div style="width: 50px" class="d-flex justify-content-around d-middle">
                                        <img :src="bandera" alt width="25" height="25" class="rounded-circle" />
                                    </div>
                                </template>
                            </el-input>
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <p class="text-general mt-4">Te enviaremos un código de verificación.</p>
                </div>
            </modal>
        </ValidationObserver>
        <!-- Recuperar Contraseña -->
        <!-- Codigo Contraseña -->
        <modal
        ref="CodigoSeguridad"
        titulo="Código Seguridad"
        icon="login (2)"
        adicional="Validar"
        no-aceptar
        @adicional="validarCodigoRecuperacion"
        >
            <div class="row mx-0 my-2 text-center text-muted2">
                Introduce el código que se te envió al correo electrónico o al número de teléfono registrado.
            </div>
            <div class="row mx-0 mt-3 text-center text-muted2 justify-center ">
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod1"
                    v-model="cod1"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(2)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod2"
                    v-model="cod2"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(3)"
                    @keyup.native.delete="back(1)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod3"
                    v-model="cod3"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(4)"
                    @keyup.native.delete="back(2)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod4"
                    v-model="cod4"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(5)"
                    @keyup.native.delete="back(3)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod5"
                    v-model="cod5"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @input.self="next(6)"
                    @keyup.native.delete="back(4)"
                    />
                </div>
                <div class="col-auto px-0 mx-2" style="width:61px;">
                    <el-input
                    ref="cod6"
                    v-model="cod6"
                    maxlength="1"
                    class="w-100 input-codigo"
                    @keyup.native.delete="back(5)"
                    />
                </div>
            </div>
            <div class="row mt-2 px-5">
                <div class="col mb-3 f-12 pl-5" style="color:#FF3333">
                    <p v-if="showCodeError">
                        El código ingresado no es válido
                    </p>
                </div>
            </div>
            <div class="row justify-center ">
                <div class="col f-15" style="color:#0892DD;" @click="resendCode">
                    <p class="text-center cr-pointer">
                        <template v-if="!timeOut">
                            Volver a enviar el código
                        </template>
                        <template v-else>
                            {{ segundos }} segundos
                        </template>
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import datosAcceso from "~/services/configurar/tendero-vip/datos_acceso";
import ServicioApi from "~/services/tiendas/postulacion";
import registroService from '~/services/registro/registro'

const segundosDefault = 90
export default {
    props: {
        isEmail: {
            type: Boolean,
            default: false
        },
        bandera: {
            type: String,
            default: () => ''
        }
    },
    data(){
        return {
            usuario: '',
            cod1: '',
            cod2: '',
            cod3: '',
            cod4: '',
            cod5: '',
            cod6: '',
            timer: null,
            interval: null,
            timeOut: false,
            segundos: segundosDefault,
            showCodeError: false,
            proyecto: {
                datos: {},
                paises: [],
            },
            pais: ''

        }
    },
    computed: {
        codeComplete(){
            return [this.cod1,this.cod2, this.cod3, this.cod4, this.cod5, this.cod6].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
        allCode(){
            return `${this.cod1}${this.cod2}${this.cod3}${this.cod4}${this.cod5}${this.cod6}`
        },
    },
    methods: {
        toggle(){
            setTimeout(async() => {
                
                this.limpiar()
                if(!this.isEmail){
                    await this.consultarProyecto();
                    await this.consultarPaisesProyecto(this.proyecto.datos.id);
                }
                this.$refs.modalVincular.toggle();
            }, 500);
        },
        async consultarProyecto(){
            try {
                const { data: { data }, } = await ServicioApi.consultarProyecto();
                this.proyecto.datos = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        async consultarPaisesProyecto(proyecto){
            try {
                const { data: { data }, } = await ServicioApi.consultarPaises(proyecto);
                this.proyecto.paises = data;
            } catch (error){
                this.error_catch(error);
            }
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        async resendCode(){
            try {
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true

                if(this.isEmail){
                    const params = {
                        usuario: this.usuario,
                        id: this.$usuario.id,
                        tienda: true
                    }
    
                    const { data } = await datosAcceso.vincularEnviarCodigo(params)

                }else {
                    const { indicativo } = this.proyecto.paises.find((el) => el.id == this.pais)
                    const phoneNumber = `+${indicativo}${this.usuario}`
                    const datos = await registroService.registrar(phoneNumber)
                        
                }
                

                    
                /*  let params = {
                    "usuario": this.idUser,
                    "indicativo": this.indicativo
                }
                const { data } = await authService.reenviarCodigoTemporalRegistro(params) */

            } catch (e){
                this.error_catch(e)
            }
        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
        async validarCodigoRecuperacion(){
            try {
                
                if(!this.codeComplete) return
                if (this.isEmail){
                    this.showCodeError = false
                    const obj = {
                        id: this.$usuario.id,
                        codigo: this.allCode,
                        usuario: this.usuario,
                    }
                    const { data } = await datosAcceso.checkVincularCodigoTemporal(obj)
                    if(data.exito){
                        this.notificacion('', 'Se ha vinculado correctamente', 'success')
                        this.$emit('update')
                        this.$refs.CodigoSeguridad.toggle();
                    }
    
                } else {
                    const p =  await registroService.verificarCode(this.allCode)
                    if(p){
                        const obj = {
                            telefono: this.usuario,
                        }
                        const { data } = await datosAcceso.vincularFromFirebase(obj)
                        if (data.exito){
                            this.notificacion('', 'Se ha vinculado correctamente', 'success')
                            this.$emit('update')
                            this.$refs.CodigoSeguridad.toggle();
                        }
                    }
                }
                
            } catch (error){
                if(_.get(error, 'data.mensaje') == "Código no valido"){
                    this.showCodeError = true
                }

                if (error.code == 'auth/invalid-verification-code'){
                    this.notificacion('Error', 'El código que introdujo no es el correcto', 'error')
                }
                this.error_catch(error)
            }

        },
        async generarCodigoRecuperacion(valid){
            try {
                if(!valid) return 
                const params = {
                    usuario: this.usuario,
                    id: this.$usuario.id,
                        
                }
                if(this.isEmail){
                    params['tienda'] = true
                    const { data } = await datosAcceso.vincularEnviarCodigo(params)
                    if(data.exito) this.cerrarVincular()
                } else {
                    if(!this.pais) return this.notificacion('', 'Debe seleccionar un país', 'error')

                    const { data } = await datosAcceso.vincularCheckUsuario(params)
                    if(data.exito){
                        const { indicativo } = this.proyecto.paises.find((el) => el.id == this.pais)
                        const phoneNumber = `+${indicativo}${this.usuario}`
                        const datos = await registroService.registrar(phoneNumber)
                        this.cerrarVincular()
                    }

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        cerrarVincular(){
            this.$refs.modalVincular.toggle();
            this.$refs.CodigoSeguridad.toggle();
        },
        limpiar(){
            this.$refs.validacion.reset()
            this.usuario =  ''
            this.cod1 =  ''
            this.cod2 =  ''
            this.cod3 =  ''
            this.cod4 =  ''
            this.cod5 =  ''
            this.cod6 =  ''
            this.pais =  ''
            this.timer =  null
            this.interval =  null
            this.timeOut =  false
            this.segundos =  segundosDefault
        }
    }
}
</script>

<style>

</style>